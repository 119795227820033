<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- Case Details Area Component -->
  <case-details-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import CaseDetailsArea from "../../components/CaseDetailsArea";
export default {
  name: "CaseDetails",
  components: {
    CaseDetailsArea,
    BreadcrumbArea
  },
  data() {
    return {
      breadcrumb: {
        page: "Case Details",
        pageTitle: "Case Details",
        sequence:[
          {
            page: "Case",
            pageTitle: "Case",
            pagePath: "/cases",
          },
          {
            page: "Case Details",
            pageTitle: "Case Details",
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>