<template>
  <!-- Breadcrumb Area Start -->
  <section class="ekifa-breadcrumb-area section_80">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="breadcrumb-box">
            <ul>
              <li><b-link to="/test">home</b-link></li>
              <template v-if="breadcrumb.sequence">
                <li v-for="(item, key) in breadcrumb.sequence" :key="key">
                  |
<!--                  <router-link :to="{path: item.pagePath}">{{item.page}}</router-link>-->
                  <template v-if="item.pagePath">
                    <router-link :to="{path: item.pagePath}">{{item.page}}</router-link>
                  </template>
                  <template v-else>
                    <span>{{item.page}}</span>
                  </template>
                </li>
              </template>
              <template v-else>
                <li>|</li>
                <li>
                  <template v-if="breadcrumb.pagePath">
                    <router-link :to="{path: breadcrumb.pagePath}">{{breadcrumb.page}}</router-link>
                  </template>
                  <template v-else>
                    <span>{{breadcrumb.page}}</span>
                  </template>
                </li>
              </template>
            </ul>
            <h2>{{breadcrumb.pageTitle}}</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Breadcrumb Area End -->
</template>

<script>
export default {
  name: "BreadcrumbArea",
  props: {
    breadcrumb: {
      page: '',
      pageTitle: '',
      pagePath:'',
      sequence:[]
    }
  }
}
</script>

<style scoped>

</style>